import {
  css,
  margin,
  mq,
  spacing,
  styled,
  Text,
} from '@thg-commerce/enterprise-theme'
import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { Grid, GridItem, Picture } from '@thg-commerce/gravity-system'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import { TextHorizontalAlignment } from './Banner'

export const BANNER_MAX_SIZE = {
  xs: {
    width: '600px',
    height: 'auto',
  },
  sm: {
    width: '1200px',
  },
  lg: {
    width: '1920px',
  },
}

export const BannerContainer = styled(Grid)<{
  containBanner?: boolean
  marginBottom?: string
  spacingAdjust?: boolean
  textAlign?: TextHorizontalAlignment
  hideGap?: boolean
  hasMargin?: string
}>`
  width: 100%;
  max-width: ${(props) =>
    props.containBanner
      ? props.theme.site.siteWidth
      : BANNER_MAX_SIZE.lg.width};
  margin: ${(props) =>
    props.hasMargin === 'both'
      ? `${spacing(4)} auto`
      : props.hasMargin === 'top'
      ? `${spacing(4)} auto 0`
      : props.hasMargin === 'bottom'
      ? `0 auto ${spacing(4)}`
      : props.hasMargin === 'none'
      ? `0`
      : `0 auto ${props.marginBottom || spacing(4)}`};

  grid-template-rows: auto 2fr;
  height: ${(props) => props.spacingAdjust && BANNER_MAX_SIZE.xs.height};
  text-align: ${(props) => props.textAlign || 'center'};
  gap: ${(props) =>
    props.hideGap
      ? 0
      : `calc(${props.theme.grid.columnGap} / 2)
      calc(${props.theme.grid.rowGap} * 2);`};
`

export const ImageItem = styled(GridItem)`
  margin: 0;
  min-width: 0;
`

export const StyledPicture = styled(Picture)<{
  spacingAdjust?: boolean
}>`
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: auto 4 / 3;
  max-width: ${BANNER_MAX_SIZE.xs.width};

  ${(props) => props.spacingAdjust && 'object-fit: cover; height: 100%;'}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    max-width: ${BANNER_MAX_SIZE.sm.width};
    aspect-ratio: auto 5 / 2;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    max-width: ${BANNER_MAX_SIZE.lg.width};
    aspect-ratio: auto 7 / 2;
  }
`

export const TextContainer = styled(GridItem)<{
  increaseMaxWidth?: boolean
  removePointerEvents?: boolean
  spacingAdjust?: boolean
}>`
  display: flex;
  align-items: ${(props) => props.theme.widget.banner.textAlignment};
  width: 100%;
  max-width: ${(props) =>
    props.increaseMaxWidth || props.spacingAdjust
      ? BANNER_MAX_SIZE.lg.width
      : props.theme.site.siteWidth};
  padding: 0 ${spacing(2)};
  min-width: 0;

  ${(props) => props.removePointerEvents && 'pointer-events: none;'}

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: ${spacing(4)} auto;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin: ${(props) => (props.spacingAdjust ? spacing(3) : spacing(2))} 0
      ${spacing(2)};
    ${(props) => props.spacingAdjust && `padding: 0 ${spacing(6)}`};
    align-items: center
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
  padding: 0 ${(props) =>
    props.increaseMaxWidth
      ? spacing(props.theme.widget.banner.spacePadding)
      : spacing(2)};
    margin: ${spacing(5)} auto;
  }
  }
`

export const TextGrid = styled(Grid)<{ spacingAdjust?: boolean }>`
  width: 100%;
  ${(props) => margin(props.theme.widget.banner.textContainer.margin)};
  ${(props) =>
    props.spacingAdjust && `grid-template-columns: repeat( 18, [col] 1fr );`}
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${margin({ top: 0, right: 0, bottom: 0, left: 0 })}
    ${(props) =>
      props.spacingAdjust && `grid-template-columns: repeat( 24, [col] 1fr );`}
  }

  && {
    grid-gap: 0;
  }
`

export const TextGridItem = styled(GridItem)<{
  containerBackground?: string
}>`
  margin: 0;

  background: ${(props) =>
    props.containerBackground === 'white' &&
    props.theme.colors.palette.greys.white};

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    justify-self: center;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${(props) => props.containerBackground === 'white' && spacing(3)};
    justify-self: ${(props) =>
      props.justifySelf ? props.justifySelf : 'auto'};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: ${(props) => props.containerBackground === 'white' && spacing(4)};
  }
  justify-self: start;
`

type TextProps = {
  lightText?: boolean
  spacingAdjust?: boolean
  containerBackground?: string
  textAlign?: TextHorizontalAlignment
  centerText?: boolean
  enableBannerTextOverlay?: boolean
}

export const Title = styled(SafeHtml)<TextProps>`
  color: ${(props) =>
    props.lightText
      ? props.theme.colors.palette.greys.white
      : props.theme.colors.palette.greys.darker};

  ${(props) =>
    props.spacingAdjust
      ? Text(
          props.theme.widget.GlobalPrimaryBanner.title.textStyle.entry,
          props.theme.widget.GlobalPrimaryBanner.title.textStyle.style,
          props.theme.widget.GlobalPrimaryBanner.title.textStyle.hasScaledText,
        )
      : Text(
          props.theme.widget.GlobalPrimaryBanner.title.text.textStyle.entry,
          props.theme.widget.GlobalPrimaryBanner.title.text.textStyle.style,
          props.theme.widget.GlobalPrimaryBanner.title.textStyle.hasScaledText,
        )};
  width: 100%;
  margin-bottom: ${spacing(2)};
  padding: ${(props) =>
    props.textAlign === 'center' ? `0 ${spacing(3)} 0` : 0};
  text-align: ${(props) => props.textAlign || 'center'};

  ${(props) =>
    props.lightText &&
    props.enableBannerTextOverlay &&
    `color: ${props.theme.colors.palette.greys.white};`}

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    text-align: ${(props) =>
      props.theme.widget.GlobalPrimaryBanner.textContainer.mobileAlignment ||
      props.textAlign};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    text-align: ${(props) => props.textAlign || 'center'};
    ${(props) =>
      props.spacingAdjust
        ? Text(
            props.theme.widget.GlobalPrimaryBanner.title.textStyle.entry,
            props.theme.widget.GlobalPrimaryBanner.title.textStyle.style,
            props.theme.widget.GlobalPrimaryBanner.title.textStyle
              .hasScaledText,
          )
        : Text(
            props.theme.widget.GlobalPrimaryBanner?.title?.titleSmallfont
              ?.textStyle.entry ||
              props.theme.widget.GlobalPrimaryBanner.title.text.textStyle.entry,
            props.theme.widget.GlobalPrimaryBanner?.title?.titleSmallfont
              ?.textStyle.style ||
              props.theme.widget.GlobalPrimaryBanner.title.text.textStyle.style,
          )};
    ${(props) => props.centerText && 'text-align: center;'}
    ${(props) =>
      props.lightText && `color: ${props.theme.colors.palette.greys.white};`}
    padding: 0 ${spacing(3)};
    width: ${(props) =>
      props.theme.widget.GlobalPrimaryBanner.title.smallDevicesWidth ||
      props.theme.widget.GlobalPrimaryBanner.title.width};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 100%;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    width: ${(props) => props.theme.widget.GlobalPrimaryBanner.title.width};
    ${(props) =>
      props.spacingAdjust
        ? Text(
            props.theme.widget.GlobalPrimaryBanner.title.textStyle.entry,
            props.theme.widget.GlobalPrimaryBanner.title.textStyle.style,
            props.theme.widget.GlobalPrimaryBanner.title.textStyle
              .hasScaledText,
          )
        : Text(
            props.theme.widget.GlobalPrimaryBanner?.title?.titleLargefont
              ?.textStyle.entry ||
              props.theme.widget.GlobalPrimaryBanner.title.text.textStyle.entry,
            props.theme.widget.GlobalPrimaryBanner?.title?.titleLargefont
              ?.textStyle.style ||
              props.theme.widget.GlobalPrimaryBanner.title.text.textStyle.style,
          )};
    color: ${(props) =>
      props.lightText
        ? props.theme.colors.palette.greys.white
        : props.theme.colors.palette.greys.darker};
  }
`
export const Subtitle = styled(SafeHtml)<TextProps>`
  ${(props) =>
    css`
      ${Text(
        props.theme.widget.banner.subtitle.textStyle.entry,
        props.theme.widget.banner.subtitle.textStyle.style,
      )};
    `}
  color: ${(props) =>
    props.containerBackground === 'white'
      ? props.theme.colors.palette.greys.dark
      : props.theme.colors.palette.greys.darker};

  ${(props) =>
    props.lightText &&
    props.enableBannerTextOverlay &&
    `color: ${props.theme.colors.palette.greys.white};`}

  &:not(:last-child) {
    margin-bottom: ${spacing(4)};
  }
  text-align: ${(props) => props.textAlign || 'center'};
  width: ${(props) => props.theme.widget.GlobalPrimaryBanner.subtitle.width};
  padding: ${(props) =>
    props.textAlign === 'center' ? `0 ${spacing(3)} 0` : 0};

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    text-align: ${(props) =>
      props.theme.widget.GlobalPrimaryBanner.textContainer.mobileAlignment ||
      props.textAlign};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    text-align: ${(props) => props.textAlign || 'center'};
    width: ${(props) =>
      props.theme.widget.GlobalPrimaryBanner.title.smallDevicesWidth};
    ${(props) => props.centerText && 'text-align: center'};
    ${(props) =>
      props.lightText && `color: ${props.theme.colors.palette.greys.white};`}
    padding: 0 ${spacing(3)};
  }
`

export const ButtonsContainer = styled.div<{
  textAlign?: TextHorizontalAlignment
  centerText?: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  flex-direction: column;
  justify-content: ${(props) => props.textAlign || 'center'};
  ${(props) => props.textAlign === 'center' && 'margin: auto;'}

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    flex-direction: ${(props) =>
      props.theme.widget.GlobalPrimaryBanner.textContainer.mobileAlignment
        ? 'row'
        : 'column'};
    justify-self: ${(props) =>
      props.theme.widget.GlobalPrimaryBanner.textContainer.mobileAlignment ||
      'auto'};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) => props.centerText && 'justify-content: center;'}
    justify-self: ${(props) => props.textAlign};
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0 ${spacing(3)};
  }
`

export const StyledButton = styled(Button)`
  height: fit-content;
  margin-bottom: ${spacing(2)};
  pointer-events: all;
  ${(props) =>
    props.theme.widget.banner?.button?.borderWidth &&
    `
    &, &:hover {
      border-width: ${props.theme.widget.banner?.button?.borderWidth};
    }
  `}

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    ${(props) =>
      props.theme.widget.GlobalPrimaryBanner.textContainer.mobileAlignment &&
      `
    &:not(:last-child) {
      margin-right: ${spacing(0.5)};
    }
    &:last-child {
      margin-left: ${spacing(0.5)};
    }`}
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    height: auto;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-right: ${spacing(2)};
    }
  }

  ${(props) =>
    props.theme.widget.banner?.linkHover &&
    `
    &:hover > a, &:active > a {
      text-decoration: ${
        props.theme.widget.banner.linkHover.textDecoration || 'inherit'
      }
    }
  `}
`

export const StyledLink = styled.a`
  display: block;

  &:focus-visible {
    outline: ${(props) => props.theme.colors.palette.brand.base} solid 3px;
    outline-offset: -3px;
  }
`

export const HighlightContainer = styled.p<TextProps>`
  ${Text('bodyText', 'alternate')};
  margin-bottom: ${spacing(2)};
  color: ${(props) =>
    props.containerBackground === 'white'
      ? props.theme.colors.palette.greys.dark
      : props.theme.colors.palette.greys.darker};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) => props.centerText && 'text-align: center'};
    ${(props) =>
      props.lightText && `color: ${props.theme.colors.palette.greys.dark};`}
  }
`

export const StyledLogoImage = styled(Picture)<{
  margin?: Margin
}>`
  max-width: 100%;
  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    padding: 0 ${spacing(4)} 0;
    margin: ${spacing(2)} auto;
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: 0 ${spacing(8)} 0;
    ${(props) => props.margin && margin(props.margin)};
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    padding: 0 ${spacing(2)} 0;
  }
  ${(props) => props.margin && margin(props.margin)};
`
